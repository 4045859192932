import React from "react";
import ETH from "../../assets/image/eth.svg";
import IntroBgImg from "../../assets/image/big1.png";
import IntroMainImg from "../../assets/image/big2.png";
import "./style.scss";

export default function Intro() {
  return (
    <section className="intro flex-col-reverse lg:flex-row">
      <div className="intro-left">
        <div className="intro-left-content">
          <h3 className="uppercase text-6xl font-bold intro-gradient-title lg:text-9xl">
            Rocket
          </h3>
          <div className="flex gap-5">
            <h3 className="uppercase text-6xl font-bold lg:text-9xl">Feller</h3>
            <div>
              <img src={ETH} alt="" />
            </div>
          </div>

          <p className="my-8 max-w-xl">
            Stabilize and increase your wETH by staking $ROCKETFELLER and
            sharing revenue from TAX on live dApp
          </p>
          <p>CA: 0x7AAecF9bC7E0783A1Bdacd195924256F1806cbcD</p>
          <p>Email: contact@rocketfeller.tech</p>
        </div>
        <div className="intro-left-btn-list">
          <a
            className="btn btn-small btn-primary"
            rel="noopener noreferrer"
            href="https://app.uniswap.org/#/swap?&chain=mainnet&use=v2&outputCurrency=0x7aaecf9bc7e0783a1bdacd195924256f1806cbcd"
            target="_blank"
          >
            Buy $ROCKETFELLER
          </a>
          <a
            href="https://etherscan.io/address/0xc397fadbab6f8b1b061956f0df240105fd150278"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-small btn-primary"
          >
            Tax Wallet
          </a>
        </div>
      </div>
      <div className="intro-right">
        <div className="intro-right-wrapper">
          <img className="intro-img-bg" src={IntroBgImg} alt="intro-img" />
          <div className="intro-img-main">
            <img src={IntroMainImg} alt="intro-img" />
          </div>
        </div>
      </div>
    </section>
  );
}
