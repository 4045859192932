import React from "react";
import Logo from "../../assets/image/small_logo.svg";

import "./style.scss";

export default function Footer() {
  return (
    <div className="footer-full">
      <div className="container">
        <footer className="footer">
          <div className="footer-left">
            <div className="footer-logo">
              <img src={Logo} alt="footer-logo" />
            </div>
          </div>
          <div className="footer-right">
            <ul className="footer-socials">
              <li>
                <a
                  href="https://twitter.com/RocketFellerETH"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link"
                >
                  <i className="fa-brands fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/RocketFellerETH"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link"
                >
                  <i className="fa-brands fa-telegram"></i>
                </a>
              </li>
            </ul>
            <ul className="footer-links">
              <li>
                <a
                  href="https://app.uniswap.org/#/swap?&chain=mainnet&use=v2&outputCurrency=0x7aaecf9bc7e0783a1bdacd195924256f1806cbcd"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Uniswap
                </a>
              </li>
              <li>
                <a
                  href="http://dextools.io/app/ether/pair-explorer/0x0450253cb31aD5AF6746496f065e40593c0Cb89d"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dextools
                </a>
              </li>
              <li>
                <a
                  href="https://etherscan.io/address/0x7AAecF9bC7E0783A1Bdacd195924256F1806cbcD"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contract
                </a>
              </li>
            </ul>
          </div>
        </footer>
        <div className="footer-line"></div>
        <span className="copyright center">
          2023 ® RocketFeller - All rights reserved
        </span>
      </div>
    </div>
  );
}
