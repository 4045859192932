export const CHAIN_ID = {
  BSC: 56,
  BSC_TESTNET: 97,
  MATIC: 137,
  MATIC_TESTNET: 80001,
  ETH: 1,
};

export const NETWORK_RPC = {
  [CHAIN_ID.BSC]: [
    "https://bsc-dataseed1.ninicoin.io",
    "https://bsc-dataseed.binance.org/",
    "https://bsc-dataseed1.defibit.io",
  ],
  [CHAIN_ID.BSC_TESTNET]: ["https://data-seed-prebsc-2-s3.binance.org:8545/"],
  [CHAIN_ID.MATIC]: ["https://polygon-rpc.com/"],
  [CHAIN_ID.MATIC_TESTNET]: ["https://matic-mumbai.chainstacklabs.com"],
  [CHAIN_ID.ETH]: [
    "https://eth-mainnet.nodereal.io/v1/43f9100965104de49b580d1fa1ab28c0",
  ],
};

// export const BLOCK_EXPLORER = {
//   [CHAIN_ID.BSC]: "https://bscscan.com",
//   [CHAIN_ID.BSC_TESTNET]: "https://testnet.bscscan.com/",
//   [CHAIN_ID.MATIC]: "https://polygonscan.com",
//   [CHAIN_ID.MATIC_TESTNET]: "https://mumbai.polygonscan.com/",
//   [CHAIN_ID.ETH]: "https://etherscan.io/",
// };

// export const CHAIN_PARAMS = {
//   [CHAIN_ID.BSC]: {
//     chainId: "0x38",
//     chainName: "Binance Smart Chain",
//     nativeCurrency: {
//       name: "bnb",
//       symbol: "BNB",
//       decimals: 18,
//     },
//     rpcUrls: NETWORK_RPC[CHAIN_ID.BSC],
//     blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.BSC]],
//   },
//   [CHAIN_ID.BSC_TESTNET]: {
//     chainId: "0x61",
//     chainName: "Binance Smart Chain Testnet",
//     nativeCurrency: {
//       name: "bnb",
//       symbol: "BNB",
//       decimals: 18,
//     },
//     rpcUrls: NETWORK_RPC[CHAIN_ID.BSC_TESTNET],
//     blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.BSC_TESTNET]],
//   },
//   [CHAIN_ID.MATIC]: {
//     chainId: "0x89",
//     chainName: "Matic",
//     nativeCurrency: {
//       name: "Matic",
//       symbol: "MATIC",
//       decimals: 18,
//     },
//     rpcUrls: NETWORK_RPC[CHAIN_ID.MATIC],
//     blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.MATIC]],
//   },
//   [CHAIN_ID.MATIC_TESTNET]: {
//     chainId: "0x89",
//     chainName: "Matic",
//     nativeCurrency: {
//       name: "Matic",
//       symbol: "MATIC",
//       decimals: 18,
//     },
//     rpcUrls: NETWORK_RPC[CHAIN_ID.MATIC_TESTNET],
//     blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.MATIC_TESTNET]],
//   },
//   [CHAIN_ID.ETH]: {
//     chainId: "0x1",
//     chainName: "Ethereum",
//     nativeCurrency: {
//       name: "Ethereum",
//       symbol: "ETH",
//       decimals: 18,
//     },
//     rpcUrls: NETWORK_RPC[CHAIN_ID.ETH],
//     blockExplorerUrls: [BLOCK_EXPLORER[CHAIN_ID.ETH]],
//   },
// };
